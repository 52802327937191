<template>
  <div class="side-bar">
    <b-list-group class="mb-5">
      <b-list-group-item class="top"
        ><div class="cs-textstyle-paragraph-bold pt-1">Filters</div>
        <cs-button fill="outline" size="small" @click="resetFilters"
          >Reset</cs-button
        ></b-list-group-item
      >
      <b-list-group-item v-b-toggle.accordion-1 class="top text-white bg-dark"
        ><div><i class="cs-icons-doc-filled"></i> Audience filter</div>
        <i class="cs-icons-expand-arrow-filled"></i>
      </b-list-group-item>
      <b-collapse id="accordion-1" visible accordion="audience" role="tabpanel">
        <b-card-body>
          <cs-select
            v-for="filter in audianceFilter"
            :key="filter.value"
            :options="filter.options"
            :label="filter.label"
            @select="assignSelectedFilter(filter.value)"
            @input="selectOption"
            v-model="filter.selected"
          />
        </b-card-body>
      </b-collapse>

      <b-list-group-item v-b-toggle.accordion-2 class="top text-white bg-dark"
        ><div><i class="cs-icons-doc-filled"></i> Content filter</div>
        <i class="cs-icons-expand-arrow-filled"></i>
      </b-list-group-item>
      <b-collapse id="accordion-2" visible accordion="content" role="tabpanel">
        <b-card-body>
          <cs-select
            v-for="filter in contentFilter"
            :key="filter.value"
            :options="filter.options"
            :label="filter.label"
            @select="assignSelectedFilter(filter.value)"
            @input="selectOption"
            :value="addedFilters[filter.value]"
            v-model="filter.selected"
          />
        </b-card-body>
      </b-collapse>
    </b-list-group>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import ListFilterOption from '@/gql/filter/ListFilterOption.gql';

export default {
  data() {
    return {
      selectedFilter: '',
      currentFilterOption: [],
      audianceFilter: [
        {
          label: 'Job Title',
          value: 'job_title',
          options: [],
          selected: '',
        },
        {
          label: 'Job Level',
          value: 'job_level',
          options: [],
          selected: '',
        },
        {
          label: 'Industry',
          value: 'industry',
          options: [],
          selected: '',
        },
        // {
        //   label: 'Current Organization',
        //   value: 'organization',
        //   options: [],
        //   selected: '',
        // },
        {
          label: 'Organization Size',
          value: 'organization_size',
          options: [],
          selected: '',
        },
        {
          label: 'Annual Revenue',
          value: 'annual_revenue',
          options: [],
          selected: '',
        },
      ],
      contentFilter: [
        {
          label: 'Sentiment',
          value: 'sentiment',
          options: [],
          selected: '',
        },
        {
          label: 'Emotion',
          value: 'emotion',
          options: [],
          selected: '',
        },
        {
          label: 'Formats',
          value: 'format',
          options: [],
          selected: '',
        },
        {
          label: 'Market',
          value: 'market',
          options: [],
          selected: '',
        },
        {
          label: 'Topic',
          value: 'topic',
          options: [],
          selected: '',
        },
        {
          label: 'Tags',
          value: 'tag',
          options: [],
          selected: '',
        },
        {
          label: 'Style',
          value: 'style',
          options: [],
          selected: '',
        },
      ],
      jobTitle: '',
      addedFilters: {},
    };
  },
  methods: {
    //  adding this function for select insted of click because click is not working for cs-select in safari 
    assignSelectedFilter(value) {
      this.selectedFilter = value
    },
    selectOption(value) {
      if(this.addedFilters.__ob__)
        delete this.addedFilters.__ob__ 
      if (!value) delete this.addedFilters[this.selectedFilter];
      else this.addedFilters[this.selectedFilter] = value;
      $bus.$emit('filter-update', this.addedFilters);
    },
    async getFilterOption(filter, isEmotion = false) {
      const { data } = await this.$apollo.query({
        query: ListFilterOption,
        variables: {
          filter_key: filter.value,
        },
      });
      let filterOptions = [];
      data.filterOption.forEach((option) => {
        // Temp fix for removing positive and negetive from emotion filter
        if(!(isEmotion && (option === 'Negative' || option === 'Positive')))
          filterOptions.push({
            label: option,
            value: option,
          });
      });
      return filterOptions;
    },
    resetFilters() {
      this.addedFilters = {};
      this.audianceFilter.map((data) => (data.selected = ''));
      this.contentFilter.map((data) => (data.selected = ''));
      $bus.$emit('filter-update', this.addedFilters);
    },
  },
  mounted() {
    $bus.$on('reset-filters', () => {
      this.resetFilters();
    });
    this.audianceFilter.map(
      async (filter) => (filter.options = await this.getFilterOption(filter))
    );
    this.contentFilter.map(
      async (filter) => {
        // Fix for removing Positive and negitive from emotion filter
        if(filter.value === 'emotion')
          filter.options = await this.getFilterOption(filter, true)
        else
          filter.options = await this.getFilterOption(filter)
      }
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-bar {
  background-color: var(--cs-gray-00);
  overflow-y: auto;
  height: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
}
* >>> .cs-select__label {
  width: 100% !important;
}
</style>
