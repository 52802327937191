<template>
  <div>
    <cs-spinner type="circles" v-if="$apollo.queries.report.loading" />
    <div v-if="!$apollo.queries.report.loading" class="ct-chart-container">
      <bar-chart
        v-if="interestChartData"
        :initial-data="interestChartData"
        title="Audience Interest"
        :colors="chartDisplayDetails.colorDetail.dataChartColors"
        :label="chartDisplayDetails.label"
        data-type="INTEREST"
      />
      <div v-if="breakdownKey !== 'subject'">
        <bar-chart
          v-if="emotionChartData && emotionChartData.length"
          :initial-data="emotionChartData"
          title="Audience Emotion"
          :stack-details="chartDisplayDetails.colorDetail.emotions"
          :label="chartDisplayDetails.label"
          data-type="EMOTION"
        />
        <bar-chart
          v-if="sentimentChartData && sentimentChartData.length"
          :initial-data="sentimentChartData"
          title="Audience Sentiment"
          :stack-details="chartDisplayDetails.colorDetail.sentiments"
          :label="chartDisplayDetails.label"
          data-type="SENTIMENT"
        />
      </div>
      <OverallTab
        v-else
        :report="report[0]"
      />
    </div>
  </div>
</template>

<script>
import $bus from '@/services/bus'
import BarChart from '@/components/charts/BarChart.vue';
import OverallTab from '@/components/audienceReport/OverallTab.vue';

import { emotions, sentiments } from '../../config/chartInfo.ts';

// Color variant for charts
import { colors, subjectChartColor } from '../../assets/chart';
import GetReport from '@/gql/report/GetReport.gql';

export default {
  components: {
    BarChart,
    OverallTab
  },
  props: {
    breakdownKey: {
      type: String,
      required: true,
    }
  },
  apollo: {
    report: {
      query: GetReport,
      variables() {
        return {
          breakdown_type:this.breakdownKey,
          filters: this.filters
        };
      },
      result({data}) {        
        this.dataUpdate(data.report)
      }
    },  
  },
  watch: {
    breakdownKey() {
      this.$apollo.queries.report.refetch({
        breakdown_type:this.breakdownKey,
        filters: this.filters
      });
    }
  },
  mounted() {    
    $bus.$on('filter-update', (filters) => {
      this.filters = filters
      this.$apollo.queries.report.refetch({
        breakdown_type:this.breakdownKey,
        filters: filters
      });
    });
  },
  computed: {
    chartDisplayDetails() {
      if(this.breakdownKey === 'subject')
        return {
          colorDetail: subjectChartColor,
          label: 'Subject'
        }
      if(this.report) {  
        let emotionTyoes = emotions;
        let sentimentTypes = sentiments;
        for (let index = 0; index < emotionTyoes.length; index++){
          emotionTyoes[index].color = this.colors[this.breakdownKey].emotions[index];
        }
        for (let position = 0; position <  sentimentTypes.length; position++)
          sentimentTypes[position].color = this.colors[this.breakdownKey].sentiments[position];
        return {
          colorDetail: {
            dataChartColors: this.colors[this.breakdownKey].interest,
            emotions: emotionTyoes,
            sentiments: sentimentTypes
          },
          label: this.breakdownKey
        }
      }
    }
  },
  data() {    
    return {
      filters: {},
      colors: colors,
      emotionChartData: [],
      sentimentChartData: [],
      interestChartData: []
    };
  },
  methods: {
    async dataUpdate() {      
      this.emotionChartData = [];
      this.sentimentChartData = [];
      this.interestChartData = [];
      let reportData = JSON.parse(JSON.stringify(this.report));
      reportData.sort((a, b) => b.interest_level - a.interest_level);
      reportData.forEach((data) => {
        this.interestChartData.push({
          name: data.name,
          value: data.interest_level
        });
        this.emotionChartData.push({
          name: data.name,
          value: data.emotion_data,
        });                
        this.sentimentChartData.push({
          name: data.name,
          value: data.sentiment_data,
        });        
      });
    }
  }
};
</script>

<style scoped>
.ct-chart-container {
  background: var(--cs-gray-00);
  padding: 20px;
  border: 1px solid var(--cs-gray-02);
}
</style>
