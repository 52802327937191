<template>
  <div>
    <b-row>
      <b-col class="contrend-title">
        {{ title }}
      </b-col>
      <b-col cols="2" class="ct-chart-icons">
        <i
          class="cs-icons-chart-bar mr-2"
          :class="isChart ? 'active-icon' : ''"
          @click="isChart = true"
        />
        <i
          class="cs-icons-data"
          :class="isChart ? '' : 'active-icon'"
          @click="isChart = false"
        />
      </b-col>
    </b-row>
    <div class="show-data" v-if="!showEmptyState">
      <div v-if="isChart">
        <Bar
          v-if="chartData && chartData.datasets && chartOptions"
          class="ct-chart"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :width="width"
          :height="height"
        />
        <div class="ct-chart-detail">
          <div
            v-for="colorinfo in chartColors"
            :key="colorinfo.label"
            class="color-wrap"
          >
            <div
              :style="{ backgroundColor: colorinfo.color }"
              class="color-info"
            />
            <div>{{ colorinfo.label }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isChart">
        <report-table
          :breakdown-title="label"
          :table-type="dataType"
          :items="initialData"
        />
      </div>
    </div>
    <app-empty-state v-if="showEmptyState"></app-empty-state>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import AppEmptyState from '@/components/general/EmptyState.vue';
import ReportTable from '@/components/audienceReport/ReportTable.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: 'BarChart',
  components: {
    Bar,
    AppEmptyState,
    ReportTable,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 300,
    },
    initialData: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    stackDetails: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    initialData() {      
      this.dataUpdate();
    },
    label() {
      this.chartOptions.scales.xAxes.title.text = this.label;
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      isChart: true,
      chartColors: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        scales: {
          yAxes: {
            stacked: true,
            title: {
              display: true,
              text: 'Interest Level',
            },
          },
          xAxes: {
            stacked: true,
            title: {
              display: true,
              text: `${this.label}`,
            },
          },
        },
      },
    };
  },
  methods: {
    dataUpdate() {      
      if (this.showEmptyState) return;
      this.chartData = {
        labels: [],
        datasets: [],
      };
      this.chartColors = [];
      if (this.dataType === 'EMOTION' || this.dataType === 'SENTIMENT') {
        const dataSets = [];
        this.stackDetails.forEach((stackDetail) => {          
          dataSets.push({
            type: 'bar',
            label: stackDetail.label,
            backgroundColor: stackDetail.color,
            name: stackDetail.name,
            data: [],
            barThickness: 39,
            maxBarThickness: 42,
            grouped: false,
          });
        });
        for (let position = 0; position < 10; position++) {
          const data = this.initialData[position];
          if(data) {
            this.chartData.labels.push(data.name);
            dataSets.forEach((dataSet) => {              
              dataSet.data.push(data.value[dataSet.name]);
            });
          }
        }
        this.chartData.datasets = dataSets;
        this.chartColors = this.stackDetails;
      } else {
        const dataSets = [];
        const colors = [];
        for (let position = 0; position < 10; position++) {
          const data = this.initialData[position];
          if(data) {            
            this.chartData.labels.push(data.name);
            dataSets.push(data.value);
            this.chartColors.push({
              color: this.colors[position]
                ? this.colors[position]
                : this.colors[0],
              label: data.name,
            });
            colors.push(
              this.colors[position] ? this.colors[position] : this.colors[0]
            );
          }
        }
        this.chartData.datasets.push({
          backgroundColor: colors,
          data: dataSets,
          barThickness: 39,
          maxBarThickness: 42,
          grouped: false,
        });
      }      
    },
  },
  computed: {
    showEmptyState() {
      return !this.initialData || !this.initialData.length;
    },
  },
  mounted() {
    this.dataUpdate();
  },
};
</script>

<style scoped>
.contrend-title{
  font-size: 20px;
  color: var(--cs-gray-07);
  margin-bottom: 32px;
  margin-top: 10px;
}
.ct-chart {
  margin: 30px 0 20px 10px;
  height: 500px;
  /* width: 98%; */
}
.ct-chart-detail {
  margin: 30px 30px 60px 30px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.color-info {
  height: 10px;
  /* margin: 5px 0; */
  width: 10px;
}
.color-wrap {
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.color-wrap div:last-child {
  margin-left: 10px;
}
.active-icon {
  color: #c31d46;
}
i.cs-icons-chart-bar,
i.cs-icons-data {
  cursor: pointer;
}
.ct-chart-icons i {
  float: right;
  margin-left: 10px;
}
</style>
