<template>
  <div>
    <b-row>
      <b-col class="contrend-title">
        {{ title }}
      </b-col>
      <b-col cols="3" class="ct-chart-icons">
        <i
          class="cs-icons-chart-bar mr-2"
          :class="isChart ? 'active-icon' : ''"
          @click="isChart = true"
        />
        <i
          class="cs-icons-data"
          :class="isChart ? '' : 'active-icon'"
          @click="isChart = false"
        />
      </b-col>
    </b-row>
    <b-row :class="isChart ? '' : 'ct-chart-wrap'">
      <b-col class="ct-pie-chart">
        <Pie
          v-if="chartData && chartData.datasets && isChart"
          class="ct-chart"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        <div v-if="!isChart">
          <report-table
            breakdown-title="subject"
            :table-type="chartId"
            :items="tableData"
          />
        </div>
      </b-col>
    </b-row>
    <app-empty-state v-if="showEmptyState"></app-empty-state>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy';
import AppEmptyState from '@/components/general/EmptyState.vue';
import ReportTable from '@/components/audienceReport/ReportTable.vue';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'PieChart',
  components: {
    Pie,
    AppEmptyState,
    ReportTable,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    chartId: {
      type: String,
      default: 'pie-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    // plugins: {
    //   type: Array,
    //   default: () => [],
    // },
    chartData: {
      type: Object,
      default: () => {},
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isChart: true,
      plugins: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'right',
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItem, ctx) => {                
                const dataset = this.chartData.datasets[0]
                const currentValue = tooltipItem.parsed;
                const percentage = (currentValue*100 / this.totalSun).toFixed(2)+"%";
                return `${tooltipItem.label} : ${tooltipItem.formattedValue}(${percentage})`
              }
            }
          }
        },
      },
    };
  },
  computed: {
    showEmptyState() {
      return !this.chartData || !this.chartData.datasets;
    },
    totalSun() {
      if(!this.showEmptyState) {
        let sum = 0;
        const dataArr = this.chartData.datasets[0].data;        
        dataArr.map(data => {
          sum += data;
        });
        return sum;
      }
    }
  }
};
</script>

<style scoped>
/* .ct-chart {
  margin: 30px 0 60px 0;
} */
.ct-chart-icons i {
  float: right;
  margin-left: 10px;
}
.active-icon {
  color: #c31d46;
}
.ct-pie-chart {
  width: 500px;
  overflow: auto;
}
.ct-chart-wrap{
  width: 100%;
}
</style>
