<template>
  <div class="ct-password-input">
    <cs-input
      v-bind="$attrs"
      :type="inputType"
      :placeholder="placeholder"
      :value="value"
      :label="label"
      class="input-class"
      @input="onInput"
    />
    <i :class="[eyeIcon, hasLabel]" @click="showPassword = !showPassword" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Enter password',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    eyeIcon() {
      return this.showPassword ? 'cs-icons-eye-hide' : 'cs-icons-eye';
    },
    inputType() {
      return this.showPassword ? 'text' : 'password';
    },
    hasLabel() {
      return !this.label ? 'ct-password-input__icon--no-label' : 'test';
    },
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event);
    },
  },
};
</script>

<style lang="css" scoped>
.ct-password-input {
  position: relative;
  width: 100%;
}
.input-class {
  width: 100%;
}
.ct-password-input i {
  font-size: 18px;
  position: absolute;
  top: 40px;
  right: 15px;
  color: var(--cs-gray-04);
}
.ct-password-input i.ct-password-input__icon--no-label {
  top: 10px;
}
</style>
