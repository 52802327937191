const colors = {
  topic: {
    interest: [
      '#AFFFEC',
      '#9ADACA',
      '#8ABAAE',
      '#657B76',
      '#44645C',
      '#29403A',
      '#08201A',
      '#7AB2A5',
      '#496A63',
      '#415F59',
    ],
    emotions: [
      '#2F4B7C',
      '#003F5C',
      '#A05195',
      '#F95D6A',
      '#FFA600',
      '#D45087',
      '#665191',
      '#FF7C43',
    ],
    sentiments: [
      '#EDF7FE',
      '#AEDBF4',
      '#5E9CD0',
      '#1C5198',
      '#011C48'
    ]
  },
  tag: {
    interest: [
      '#F6FD94',
      '#D1E791',
      '#AAD08F',
      '#A9BA9D',
      '#8A9682',
      '#8EBB6E',
      '#A7BD67',
      '#6F9852',
      '#42791B',
      '#1F4B00',
    ],
    emotions: [
      '#2F4B7C',
      '#003F5C',
      '#A05195',
      '#F95D6A',
      '#FFA600',
      '#D45087',
      '#665191',
      '#FF7C43',
    ],
    sentiments: [
      '#EDF7FE',
      '#AEDBF4',
      '#5E9CD0',
      '#1C5198',
      '#011C48'
    ]
  },
  industry: {
    interest: [
      '#FFFDCB',
      '#F5D4B1',
      '#E6AA88',
      '#FAA575',
      '#D6885D',
      '#B2704A',
      '#CE6C52',
      '#B55824',
      '#8B4117',
      '#652704'
    ],
    emotions: [
      '#2F4B7C',
      '#003F5C',
      '#A05195',
      '#F95D6A',
      '#FFA600',
      '#D45087',
      '#665191',
      '#FF7C43',
    ],
    sentiments: [
      '#EDF7FE',
      '#AEDBF4',
      '#5E9CD0',
      '#1C5198',
      '#011C48'
    ]
  },
  market: {
    interest: [
      '#AFFFEC',
      '#9ADACA',
      '#8ABAAE',
      '#657B76',
      '#44645C',
      '#29403A',
      '#08201A',
    ],
    emotions: [
      '#2F4B7C',
      '#003F5C',
      '#A05195',
      '#F95D6A',
      '#FFA600',
      '#D45087',
      '#665191',
      '#FF7C43',
    ],
    sentiments: [
      '#EDF7FE',
      '#AEDBF4',
      '#5E9CD0',
      '#1C5198',
      '#011C48'
    ]
  }
};



const subjectChartColor = {
  dataChartColors: ['#F86624', '#41B8D5'],
};
const emotionColors = [
  '#003F5C',
  '#2F4B7C',
  '#665191',
  '#A05195',
  '#D45087',
  '#F95D6A',
  '#FF7C43',
  '#FFA600',
];
const sentimentColors = [
  '#EDF7FE',
  '#AEDBF4',
  '#5E9CD0',
  '#1C5198',
  '#011C48'
];
export {
  sentimentColors,
  colors,
  subjectChartColor,
  emotionColors
};
