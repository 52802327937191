<template>
  <div class="report-table-container">
    <b-table
      sticky-header
      :items="items"
      :fields="fields"
      head-variant="dark"
      borderless
      hover
      striped
    >
      <template #cell(No)="data">
        {{ data.index + 1 }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    breakdownTitle: {
      type: String,
      required: true,
    },
    tableType: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    fields() {
      if (this.tableType === 'INTEREST')
        return [
          'No',
          {
            key: 'name',
            label: this.breakdownTitle,
          },
          {
            key: 'value',
            label: 'Interest Level',
            formatter: 'roundOffValue'
          },
        ];
      
      let finalFields = [
        'No',
        {
          key: 'name',
          label: this.breakdownTitle,
        },
      ]
      Object.keys(this.items[0].value).forEach((key)=>{    
        if(key !== '__typename') 
          finalFields.push({
            test: key,
            v: this.items[key],
            key: `value.${key}`,
            label: this.labelFinder(key),
            formatter: 'roundOffValue'
          })
      });
      return finalFields;
    },

  },
  methods: {
    roundOffValue(value) {
      return Math.round(value);
    },
    labelFinder(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult
    }
  }
};
</script>

<style scoped>
.report-table-container {
  margin-bottom: 40px;
}
</style>
