import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login/Login.vue';
import Home from '@/views/Home.vue';
import AudienceReport from '@/views/AudienceReport/index.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/report',
    children: [
      {
        path: 'report',
        name: 'Report',
        component: AudienceReport,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  // next();

  if ($auth.isLoggedIn() && to.fullPath.includes('/login')) {
    // Logged in but trying to access an onboarding screen
    next('/');
  } else if (!$auth.isLoggedIn() && !to.fullPath.includes('/login')) {
    // Logged out but trying to access an app screen
    next('/login');
  } else {
    next();
  }
});
export default router;
