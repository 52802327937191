<template>
  <b-row class="ct-login">
    <b-col cols="4">
      <div class="ct-login__left">
        <div class="ct-login__left--top">
          <div class="ct-login__left--top__text">
            <img :src="vector" class="vector-logo" alt="" /><span>ntrend</span>
          </div>
          <div class="ct-login__left--top__heading">
            <div>AUDIENCE REPORT</div>
          </div>
        </div>
        <img :src="logo" class="ct-login__vector" alt="" />
      </div>
    </b-col>
    <b-col cols="8" class="ct-login__right position-relative">
      <img :src="tree" class="ct-login__background" alt="" />
      <div class="ct-login__right--body">
        <cs-card class="ct-login__right--card">
          <div slot="header" class="ct-login__right--title">
            <div>LOGIN</div>
            <cs-alert
              v-if="error"
              variant="danger"
              can-close
              class="mt-2"
              @close="close"
            >
              <div slot="title">Error with Login</div>
              <div>Try again.</div>
              <i slot="close-icon" class="cs-icons-close" />
            </cs-alert>
            <div class="cs-textstyle-paragraph ct-login__info">
              Login with your official contrend credentials.
            </div>
          </div>
          <div slot="body" class="ct-login__right--buttons">
            <cs-input
              v-model="email"
              type="text"
              label="Email"
              placeholder="Enter email"
            />
            <app-password-input v-model="password" label="Password" />
            <cs-button corners="rounded" class="login-button" @click="login"
              >LOGIN</cs-button
            >
          </div>
        </cs-card>
      </div>
      <div class="ct-login__footer">
        <div class="mx-2">
          <a href="mailto:hello@efficientcloud.com">Contact Us</a>
        </div>
        <div class="mx-2">
          <a target="_blank" rel="noopener noreferrer" :href="helpUrl">Help</a>
        </div>
        <div class="mx-2 visible">
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import AppPasswordInput from '@/components/general/PasswordInput.vue';
import Login from '@/gql/login/Login.gql';
const vector = require('@/assets/contrend-logo.svg');
const logo = require('@/assets/contrend-login.png');
const tree = require('@/assets/tree.png');

export default {
  components: {
    AppPasswordInput,
  },
  data() {
    return {
      vector,
      logo,
      tree,
      password: '',
      email: '',
      error: false,
    };
  },
  computed: {
    helpUrl() {
      return 'hello';
    },
  },
  methods: {
    async login() {
      this.error = false;
      try {        
        const { data } = await this.$apollo.mutate({
          mutation: Login,
          variables: {
            email: this.email,
            password: this.password,
          },
        });
        localStorage.setItem('access_token', data.login);
        this.$router.push({ path: '/report' });
      } catch {
        this.error = true;
      }
    },
    close() {
      this.error = false;
    },
  },
};
</script>

<style scoped>
.ct-login {
  display: flex;
  flex-direction: row;
  background-color: var(--cs-gray-02);
  height: 100vh;
}
.ct-login__left {
  display: flex;
  flex-direction: column;
  background: #c31d46;
  height: 100vh;
  justify-content: space-between;
}
.ct-login__vector {
  width: 100%;
  display: flex;
  object-fit: cover;
  justify-self: flex-end;
}
.ct-login__left--top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30%;
}
.ct-login__left--top__text {
  color: var(--cs-gray-00);
  font-size: 30px;
  font-weight: 600;
}
.ct-login__left--top__text--small {
  font-weight: 100;
}
.ct-login__left--top__heading {
  text-align: center;
  color: var(--cs-gray-00);
  font-size: 30px;
  font-weight: 400;
  margin-top: 5px;
}
.ct-login__right--card {
  display: flex;
  width: 450px;
  height: 450px;
  padding: 10px;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.ct-login__info {
  color: var(--cs-gray-05);
  font-size: 16px;
  margin-top: 5px;
}
.ct-login__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--cs-accent-dark);
  height: 150px;
}
.ct-login__right--title {
  padding: 10px 20px;
  text-align: left;
}
.ct-login__right {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}
.ct-login__right--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--cs-gray-00);
}
.ct-login__right--buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 20px;
}
.ct-login__button {
  --cs-button-padding: 13px !important;
}
.ct-login__right--footer {
  padding: 10px 20px;
  width: 350px;
  text-align: center;
}
.ct-login__right--footer a {
  color: var(--cs-primary-base);
}
.ct-login__footer {
  background: var(--cs-gray-00);
  border-top: 1px solid var(--cs-gray-02);
  display: flex;
  padding: 10px;
  justify-self: flex-end;
  justify-content: center;
  flex-direction: row;
  color: var(--cs-gray-04);
  gap: 30px;
}

.row > * {
  padding: 0px;
}
.ct-login__background {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 61%;
}
.visible {
  z-index: 99;
}
.login-button {
  --cs-button-color: #c31d46 !important;
}
.vector-logo {
  width: 40px;
  margin-right: 1px;
  margin-top: -6px;
}
* {
  font-family: 'Roboto', sans-serif;
}
</style>
