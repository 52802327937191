const emotions = [
  {
    label: 'Anger',
    color: '',
    name: 'Anger',
  },
  {
    label: 'Sadness',
    color: '',
    name: 'Sadness',
  },
  {
    label: 'Disgust',
    color: '',
    name: 'Disgust',
  },
  {
    label: 'Fear',
    color: '',
    name: 'Fear',
  },
  {
    label: 'Trust',
    color: '',
    name: 'Trust',
  },
  {
    label: 'Anticipation',
    color: '',
    name: 'Anticipation',
  },
  {
    label: 'Surprise',
    color: '',
    name: 'Surprise',
  },
  {
    label: 'Joy',
    color: '',
    name: 'Joy',
  },
  
  // {
  //   label: 'Positive',
  //   color: '',
  //   name: 'Positive',
  // },
  // {
  //   label: 'Negative',
  //   color: '',
  //   name: 'Negative',
  // }
]
const sentiments = [
  {
    label: 'Very Positive',
    color: '',
    name: 'VeryPositive',
  },
  {
    label: 'Positive',
    color: '',
    name: 'Positive',
  },
  {
    label: 'Neutral',
    color: '',
    name: 'Neutral',
  },
  {
    label: 'Negative',
    color: '',
    name: 'Negative',
  },
  {
    label: 'Very Negative',
    color: '',
    name: 'VeryNegative',
  },
]

export {
  emotions,
  sentiments
}
