<template lang="html">
  <span class="avatar" :class="['avatar--' + size]" :style="customStyle">
    <div class="avatar__content">
      <div class="avatar__initials">{{ initials }}</div>
      <app-img
        v-if="picture"
        :src="picture"
        :original="original"
        :width="width"
        :height="height"
        class="avatar__img"
      />
    </div>
  </span>
</template>

<script>
// https://stackoverflow.com/a/3426956 for text to hash to rgb
import AppImg from '@/components/common/Img.vue';

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}
function intToRGB(i) {
  // eslint-disable-next-line no-bitwise
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}
function textColor(text) {
  return intToRGB(hashCode(text));
}
export default {
  components: {
    AppImg,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    picture: {
      type: String,
      required: false,
      default: null,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    height: {
      type: Number,
      required: false,
      default: null,
    },
    original: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
      validator(x) {
        return ['small', 'medium', 'large'].indexOf(x) > -1;
      },
    },
  },
  computed: {
    customStyle() {
      const color = this.name
        ? `#${textColor(this.name)}`
        : 'var(--primary-base)';
      return `--avatar-color: ${color}`;
    },
    initials() {
      const initials = (this.name && this.name.match(/\b\w/g)) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    },
  },
};
</script>

<style lang="css" scoped>
.avatar {
  line-height: --avatar-size;
  --avatar-color: var(--cs-primary-base);
  --avatar-size: 35px;
  --avatar-font-size: 12px;
}
.avatar__content {
  display: block;
  position: relative;
  height: var(--avatar-size);
  width: var(--avatar-size);
  line-height: 1;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  border: 2px solid #fff;
}
.avatar--small {
  --avatar-size: 35px;
  --avatar-font-size: 12px;
}
.avatar--medium {
  --avatar-size: 55px;
  --avatar-font-size: 16px;
}
.avatar--large {
  --avatar-size: 85px;
  --avatar-font-size: 22px;
}
.avatar__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.avatar__initials {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--cs-gray-00);
  font-weight: var(--cs-font-weight-bold);
  font-size: var(--avatar-font-size);
  background-color: var(--avatar-color);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
</style>
