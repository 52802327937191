<template>
  <div class="empty-state-box">
      <img src="@/assets/empty-state-icon.svg"/>
      <cs-empty-state 
        title='Insufficient data for analysis. '
        description='Please reset the filters.'>
          <cs-button
            slot="button"
            variant="primary"
            fill="solid"
            @click="resetFilters">
            Reset
          </cs-button>
      </cs-empty-state>
    </div>
</template>

<script>
import $bus from '@/services/bus'

export default {
  methods: {
    resetFilters() {
      $bus.$emit('reset-filters');
    }
  },
}
</script>
<style scoped>
.empty-state-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}
.empty-state-box img {
  width: 100px;
}
</style>