
export const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCaQCAIs+0y/9d+KXrc/367Md1S
UXZ4PGlP4jBT2eiXPsn4UmBWJHE+QXnPiwmcwqPzZsrdVqSU+QwJ5etE6RdDAm3w
eGesZKcbh6GaK2OCSYbyL/FTVCLWtCQbxUIZLL4FnkspaWJBX4sBb923UVKRX3rY
ecDoIiWCfG1WGXq2lwIDAQAB
-----END PUBLIC KEY-----`;

export const signOptions = {
  algorithm: 'RS256',
};
