<template>
  <div class="full-height">
    <app-topbar />
    <b-row>
      <b-col cols="4" md="2" class="ct-side-bar"><app-sidebar /></b-col>
      <b-col cols="8" md="10" class="router-view"><router-view /></b-col>
    </b-row>
  </div>
</template>

<script>
import AppSidebar from '@/components/common/Sidebar.vue';
import AppTopbar from '@/components/common/Topbar.vue';
export default {
  name: 'Home',
  components: {
    AppSidebar,
    AppTopbar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.full-height {
  height: 100vh;
  position: relative;
}
.full-height .row {
  height: 100%;
}
.router-view {
  padding: 32px;

  height: 100%;
  overflow-y: auto;
}
.ct-side-bar {
  height: 100%;
  max-width: 285px;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--cs-gray-03);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
