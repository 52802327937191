<template>
  <div>
    <div class="contrend-dashboard__header-title">
      {{ subjects && subjects[0].name }}
      <!-- <div>Report</div> -->
      <hr />
    </div>
    <cs-page-tabs
			:tabs="tabs"
      @input="activeTab"
      class="ct-main-content"
		/>
    <!-- <overall-tab v-if="tabs[indexTab].key === 'subject'" /> -->
    <breakdown-charts :breakdown-key="tabs[indexTab].key" />
  </div>
</template>

<script>
import OverallTab from '@/components/audienceReport/OverallTab.vue';
import BreakdownCharts from '@/components/audienceReport/BreakdownCharts.vue';
import ListSubjects from '@/gql/subject/ListSubjects.gql';

export default {
  components: {
    OverallTab,
    BreakdownCharts
  },
  data(){
    return{
      tabs: [
        {
          label: 'Overall',
          key: 'subject',
        },
        { 
          label: 'By Topic',
          key: 'topic'
        },
        {
          label: 'By Tag',
          key: 'tag'
        },
        {
          label: 'By Industry',
          key: 'industry'
        },
        {
          label: 'By Market',
          key: 'market'
        },
      ],
      indexTab: 0,
    };
  },
  methods: {
    activeTab(val) {
      this.indexTab = val;
    }
  },
  apollo: {
    subjects: {
      query: ListSubjects,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ct-main-content {
  margin-bottom: 40px;
}

* >>> .cs-textstyle-nav-sub-link {
  font-size: 16px;
}
</style>
