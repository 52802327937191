<template>
  <div id="navbarAvatar" class="avatar" tabindex="-1">
    <app-avatar size="small" :picture="profilePicture" :name="name" />
    <span class="cs-textstyle-paragraph-extra-small-bold navbar__name">{{
      name
    }}</span>
    <i class="cs-icons-expand-arrow-filled cs-icons"></i>
    <b-popover
      ref="profilePopover"
      target="navbarAvatar"
      placement="bottomleft"
      triggers="click blur"
      custom-class="bg-dark"
    >
      <div>
        <div class="avatar__body">
          <div class="avatar__info">
            <div class="cs-textstyle-paragraph-small-bold avatar__name">
              {{ name }}
            </div>
            <div class="cs-textstyle-paragraph-extra-small avatar__name">
              {{ email }}
            </div>
          </div>
          <div class="avatar__item" @click="logout">
            <i class="cs-icons-logout"></i>
            Log Out
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import AppAvatar from '@/components/common/Avatar.vue';

export default {
  components: {
    AppAvatar,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    closeProfilePopover() {
      this.$refs.profilePopover.$emit('close');
    },
    logout() {
      this.$refs.profilePopover.$emit('close');
      localStorage.removeItem('access_token');
      localStorage.removeItem('lastActiveProject');
      localStorage.removeItem('accessLevel');
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.avatar__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--cs-gray-00);
  width: 100% !important;
  padding: 5px;
}
.avatar__info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.avatar__item:hover {
  cursor: pointer;
  color: var(--cs-gray-02);
}
.avatar__name {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-bottom: 1px solid var(--cs-gray-05);
  padding: 0em 1em 0.5em 1em;
  margin: 0 -1em;
}
.cs-icons {
  color: var(--cs-gray-00);
  margin-left: 5px;
}
.navbar__name {
  color: var(--cs-gray-00);
}
</style>
