<template>
  <div>
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex flex-column">
        <pie-chart
          chart-id="SENTIMENT"
          :chart-data="sentimentData"
          title="Overall Level of Sentiment"
          :table-data="sentimentTableData"
        />
      </div>
      <div class="d-flex flex-column">
        <pie-chart
          chart-id="EMOTION"
          title="Overall Level of Emotion"
          :chart-data="emotionalData"
          :table-data="emotionTableData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
// Color variant for charts
import {
  subjectChartColor,
  emotionColors,
  sentimentColors,
} from '../../assets/chart';
import GetReport from '@/gql/report/GetReport.gql';

export default {
  name: 'OverallTab',
  components: {
    PieChart,
    BarChart,
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  mounted() {
    const emotion_data = this.report.emotion_data;
    const sentiment_data = this.report.sentiment_data;

    Object.entries(emotion_data).forEach(([key, value]) => {
      if (key === '__typename') return;
      this.emotionalData.labels.push(key);
      this.emotionalData.datasets[0].data.push(value || 0);
    });

    Object.entries(sentiment_data).forEach(([key, value]) => {
      if (key === '__typename') return;
      this.sentimentData.labels.push(key);
      this.sentimentData.datasets[0].data.push(value || 0);
    });

    emotionColors.map((item) =>
      this.emotionalData.datasets[0].backgroundColor.push(item)
    );

    sentimentColors.map((item) =>
      this.sentimentData.datasets[0].backgroundColor.push(item)
    );
  },
  computed: {
    sentimentTableData() {
      return [{
        name: this.report.name,
        value: this.report.sentiment_data
      }]
    },
    emotionTableData() {
      return [{
        name: this.report.name,
        value: this.report.emotion_data
      }]
    }
  },
  data() {
    return {
      showChart: false,
      overallChartData: [],
      sentimentData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      emotionalData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      interestChartColors: subjectChartColor.dataChartColors,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
