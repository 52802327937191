import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';

const getHeaders = () => {
  const isLogin = window.location.href.includes('/login');
  if (localStorage.access_token && !isLogin) {    
    return {
      Authorization: `Bearer ${localStorage.access_token}`,
    };
  }
  return {};
};

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPH_ENDPOINT,
  headers: getHeaders(),
});

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
