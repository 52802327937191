<template>
  <div class="wrapper">
    <div class="top-bar">
      <img class="app-logo" :src="Logo" alt="contrend-logo" />
      <app-user-avatar :name="username" :email="user.email" :profile-picture="user.picture" />
    </div>
  </div>
</template>

<script>
import AppUserAvatar from '@/components/common/UserAvatar.vue';
import GetUser from '@/gql/user/GetUser.gql';
const Logo = require('@/assets/logo.svg');

export default {
  components: {
    AppUserAvatar,
  },
  apollo: {
    user: {
      query: GetUser,
      result(data){
        console.log('Data', data);
      }
    },
  },
  data() {
    return {
      Logo,
      user: {
        first_name: '',
        last_name: '',
        picture: '',
        email: '',
      },
    };
  },
  computed: {
    username() {
      return this.user && `${this.user.first_name} ${this.user.last_name}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bar {
  background-color: var(--cs-primary-base);
  padding: 12px 30px 12px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1;
}
.app-logo {
  height: 25.18px;
}
.wrapper{
  position: relative;
  height: fit-content;
  height: 60px;
}
</style>
